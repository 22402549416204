import React from 'react';
import Card from '../UI/card/Card';
import styles from './NoReservation.module.css';

const NoReservation = () => {
  return (
    <Card className={styles.noReservationContainer}>
      <h2>
        <strong>No reservations found</strong>
      </h2>
      <p>
        Please check the information you have entered and try again. <strong>NB:</strong>{' '}
        Reservations made up to 12 hours prior to the beginning of the outage may not be available
        via this crisis tool.
      </p>
    </Card>
  );
};

export default NoReservation;
