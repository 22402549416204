import React from 'react';
import Landing from './pages/landing/Landing';
import ReservationDetails from './pages/reservationDetails/ReservationDetails';
import { Route, Routes, useNavigate } from 'react-router-dom';
//okta imports
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuthConfig } from './utils/config';
import styles from './App.module.css';
import Spinner from './components/UI/spinner/Spinner';
function App() {
  const navigate = useNavigate();
  const oktaAuth = new OktaAuth(oktaAuthConfig);
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };

  return (
    <div className={styles.appContainer}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/reservation/:reservationId/:emailId" element={<ReservationDetails />} />
          <Route path="/login/callback" element={<LoginCallback />} />
          <Route path="*" element={<Landing />} />
        </Routes>
        <Spinner />
      </Security>
    </div>
  );
}

export default App;
