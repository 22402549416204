import React, { useState, useEffect } from 'react';
import styles from './ReservationDetails.module.css';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import Header from '../../components/header/Header';
import AppInfo from '../../components/appInfo/AppInfo';
import Sidebar from '../../components/sidebar/Sidebar';
import ReservationPropertyDetails from '../../components/reservationPropertyDetails/ReservationPropertyDetails';
import { useOktaAuth } from '@okta/okta-react';
import { reformatReservationId, reformatName } from '../../utils/utils';
import ChangeRequest from '../../components/changeRequest/ChangeRequest';
import ChangeRequestList from '../../components/changeRequestList/ChangeRequestList';
import { IChangeRequest } from '../../interfaces';
import AlertContent from '../../components/alertContent/AlertContent';
import { modes } from '../../utils/contants';
import { trackPromise } from 'react-promise-tracker';
import { getReservationDetailsByReservationId } from '../../apiCall';
import cryptoJS from 'crypto-js';

const ReservationDetails = () => {
  const [reservationDetail, setReservationDetail] = useState(Object);
  const reservationPathName = useLocation().pathname;
  const { reservationId, emailId } = useParams();
  const { oktaAuth, authState } = useOktaAuth();
  const [selectedRequest, setSelectedRequest] = useState<IChangeRequest | null>(null);
  const [crMode, setcrMode] = useState(modes.ADD);
  const [showChangeRequest, setShowChangeRequest] = useState(false);
  const [showAlert, setShowAlert] = useState({
    isAlert: false,
    alertType: ''
  });
  const [isPinCodeCorrect, setIsPinCodeCorrect] = useState(false);
  const [validatedPinNumber, setValidatedPinNumber] = useState('');
  let decodedEmailId = '';
  if (typeof emailId === 'string') {
    decodedEmailId = decodeURIComponent(emailId);
  }
  const encryptedEmailId = cryptoJS.AES.decrypt(
    decodedEmailId,
    process.env.REACT_APP_SECRET_KEY_FOR_TEXT as string
  );
  const showChangeRequestComponent = () => {
    setcrMode(modes.ADD);
    setShowChangeRequest(true);
  };
  const onEditHandler = (request: IChangeRequest) => {
    setcrMode(modes.EDIT);
    setSelectedRequest(request);
    setShowChangeRequest(true);
  };

  const alertHandler = (flag: boolean, alertType: string) => {
    setShowAlert({ isAlert: flag, alertType: alertType });
  };

  const toggleChangeRequest = (flag: boolean) => {
    setShowChangeRequest(!flag);
  };

  const pinValidationHandler = (flag: boolean, pinNumber: string) => {
    setIsPinCodeCorrect(flag);
    setValidatedPinNumber(pinNumber);
  };

  useEffect(() => {
    (async () => {
      if (authState?.isAuthenticated === false) {
        await oktaAuth.signInWithRedirect({});
      }
    })();
  }, [oktaAuth, authState?.isAuthenticated]);

  useEffect(() => {
    (async () => {
      await trackPromise(
        getReservationDetailsByReservationId({
          reservationId: reservationId as string,
          bookerEmail: encryptedEmailId.toString(cryptoJS.enc.Utf8)
        })
          .then((response) => {
            if (response.responseCode === 200) {
              setReservationDetail(response);
            }
          })
          .catch((error) => {
            error && setReservationDetail({});
          })
      );
    })();
  }, [reservationId]);
  return (
    <div
      data-testid={'reservationDetailsTestId'}
      className={styles.reservationDetailsOuterContainer}>
      {reservationDetail.reservationDetails && (
        <Helmet>
          <title>{`${reformatName(
            reservationDetail.reservationDetails[0].bookerFirstName,
            reservationDetail.reservationDetails[0].bookerLastName
          )} - ${
            reservationId && reformatReservationId(reservationId as string)
          } • Booking.com`}</title>
        </Helmet>
      )}
      <header>
        <Header />
        <AppInfo />
      </header>
      <div className={styles.reservationDetailsContainer}>
        <Sidebar
          csAgentPathName={reservationPathName}
          onCRButtonClick={showChangeRequestComponent}
          changeRequestButtonDisabled={!isPinCodeCorrect || showChangeRequest}
        />
        {reservationDetail.reservationDetails && (
          <div
            className={styles.reservationPropertyDetailsContainer}
            data-testid={'reservationPropertyDetailsTestId'}>
            {showAlert.isAlert && <AlertContent alertType={showAlert.alertType} mode={crMode} />}
            {showChangeRequest && (
              <ChangeRequest
                request={selectedRequest}
                mode={crMode}
                reservationId={reservationId?.replace(/\./g, '')}
                onAlertHandler={alertHandler}
                hideComponent={toggleChangeRequest}
                validatedPinNumber={validatedPinNumber}
              />
            )}
            <ReservationPropertyDetails
              details={reservationDetail.reservationDetails[0]}
              isPinCodeCorrectHandler={pinValidationHandler}
            />
            <ChangeRequestList
              renderagain={showAlert.isAlert}
              onEditHandler={onEditHandler}
              reservationId={reservationId?.replace(/\./g, '') ?? ''}
              isPinCodeCorrect={!isPinCodeCorrect}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReservationDetails;
