import React, { useState } from 'react';
import styles from './ReservationPin.module.css';
import { Formik, FormikErrors } from 'formik';
import Input from '../UI/input/Input';
import Button from '../UI/button/Button';
import checkmark from '../../assets/images/checkmark.png';
import { reformatReservationId } from '../../utils/utils';
import { trackPromise } from 'react-promise-tracker';
import { checkReservationPin } from '../../apiCall';
interface IOnSubmitInput {
  setSubmitting: (flag: boolean) => void;
}
interface IReservationPinFormValues {
  pinNumber: string;
}
interface IReservationPin {
  reservationId: string;
  isPinCodeCorrectHandler: (flag: boolean, pinNumber: string) => void;
}

const ReservationPin = ({ reservationId, isPinCodeCorrectHandler }: IReservationPin) => {
  const [checkPinCode, setCheckPinCode] = useState(false);
  const [pinError, setPinError] = useState('');

  const reservationPinValidateHandler = (values: IReservationPinFormValues) => {
    setPinError('');
    const errors: FormikErrors<IReservationPinFormValues> = {};
    if (values.pinNumber !== '' && !/\b\d{4}\b/g.test(values.pinNumber)) {
      setPinError('isInvalid');
    }
    return errors;
  };
  const reservationPinSubmitHandler = async (
    values: IReservationPinFormValues,
    { setSubmitting }: IOnSubmitInput
  ) => {
    if (!values.pinNumber) {
      setPinError('emptyField');
    } else {
      pinError !== 'isInvalid' &&
        (await trackPromise(checkReservationPin(reservationId, values.pinNumber))
          .then((response) => {
            if (response.responseCode === 200 && response.responseMessage === 'Success') {
              setCheckPinCode(true);
              isPinCodeCorrectHandler(true, values.pinNumber);
            } else {
              throw new Error('pinIsInvalid');
            }
          })
          .catch((error) => error && setPinError('pinIsInvalid')));
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className={styles.reservationPinContainer}>
        <div>
          <p className={styles.reservationIdLabelText}>Reservation ID</p>
          <p className={styles.reservationIdText}>
            <strong>{reformatReservationId(reservationId)}</strong>
          </p>
          {checkPinCode && (
            <div className={styles.checkPinCodeContainer}>
              <img className={styles.checkmarkImg} src={checkmark} alt={'checkmark img'} />
              <p className={styles.pinConfirmedText}>PIN confirmed</p>
            </div>
          )}
          {!checkPinCode && (
            <Formik
              initialValues={{
                pinNumber: ''
              }}
              validate={reservationPinValidateHandler}
              onSubmit={reservationPinSubmitHandler}>
              {({ values, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} className={styles.reservationPinForm}>
                  <Input
                    className={styles.pinNumberInput}
                    inputVectorImgClassName={styles.errorVectorImg}
                    data-testid={'pinNumberFormTestId'}
                    label={''}
                    id="pinNumber"
                    name="pinNumber"
                    type={'text'}
                    placeholder={'Enter 4 digit PIN'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pinNumber}
                    errorType={pinError}
                    touched={touched.pinNumber}
                  />
                  <Button
                    className={styles.checkinPinButton}
                    type={'submit'}
                    disabled={isSubmitting}>
                    Check PIN
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
      <div className={styles.reservationPinBorderBottom} />
    </>
  );
};

export default ReservationPin;
