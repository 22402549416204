import { createContext } from 'react';
import { paginationConstants } from '../utils/contants';

const ReservationContext = createContext({
  reservationId: '',
  emailAddress: '',
  firstName: '',
  lastName: '',
  hotelId: '',
  checkInDate: '',
  checkOutDate: '',
  pageNumber: paginationConstants.pageNumber,
  sortOrder: paginationConstants.sortOrder,
  sortField: paginationConstants.sortField,
  pageLimit: paginationConstants.pageLimit
});

export default ReservationContext;
