import React from 'react';
import bookingLogo from '../../assets/images/Booking-logo.png';
//NOTE: commenting img import will be uncommented once detailed requirements comes for it
import phoneIcon from '../../assets/images/Phone-icon.png';
import styles from './Header.module.css';

const Header = () => {
  return (
    <div className={styles.navbarContainer} data-testid={'headerDivTestId'}>
      <div className={styles.logoContainer}>
        <img className={styles.logo} src={bookingLogo} alt={'Booking logo'} />
        <p className={styles.headerText}>Crisis Continuity Tooling for Customer Service</p>
      </div>
      <div className={styles.connectContainer}>
        <img className={styles.phoneIcon} src={phoneIcon} alt={'Connect via phone'} />
        <a
          className={styles.externalLink}
          href="https://okta.booking.com/home/booking_amazonconnectprodlondonccp_1/0oa9x5v0xhLpg43gh417/aln9x618n7ToB80qS417"
          target="_blank"
          rel="noreferrer">
          <p className={styles.connectViaPhoneText}>
            <strong>Connect to phone system</strong>
          </p>
        </a>
      </div>
    </div>
  );
};

export default Header;
