import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { createRequest, getTopics, updateRequest } from '../../apiCall';
import { IChangeRequest } from '../../interfaces';
import styles from './ChangeRequest.module.css';
import Card from '../UI/card/Card';
import Button from '../UI/button/Button';
import { Formik } from 'formik';
import FormSelectInput from '../formSelectInput/FormSelectInput';
import { modes } from '../../utils/contants';
import FormTextInput from '../formTextInput/FormTextInput';
import { reformatReservationId } from '../../utils/utils';

interface FormValues {
  topic: string;
  subtopic: string;
  reqDetails: string;
}

interface IChangeRequestDetails {
  mode: string;
  request?: IChangeRequest | null;
  reservationId: any;
  onAlertHandler: (flag: boolean, alertType: string) => void;
  hideComponent: (flag: boolean) => void;
  validatedPinNumber: string;
}

const ChangeRequest = ({
  mode,
  request,
  reservationId,
  onAlertHandler,
  hideComponent,
  validatedPinNumber
}: IChangeRequestDetails) => {
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [changeRequest, setChangeRequest] = useState(request);
  const selectRef = useRef<HTMLButtonElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getTopics().then((response) => {
      setTopics(response);
    });
    selectRef.current?.focus();
  }, []);

  useEffect(() => {
    onAlertHandler(false, '');
    selectRef.current?.focus();
    if (request?.topic) {
      setSubtopicsForTopic(request.topic);
    }
    if (mode === modes.ADD) {
      setChangeRequest({
        ['topic']: '',
        ['subtopic']: '',
        ['reqDetails']: '',
        ['createdBy']: '',
        ['createdDate']: ''
      });
    } else {
      setChangeRequest(request);
    }
  }, [topics, request, mode]);

  const handleTopicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (changeRequest) {
      setChangeRequest({
        ...changeRequest,
        ['topic']: event.target.value
      });
    }
    setSubtopicsForTopic(event.target.value);
  };

  const handleSubTopicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (changeRequest) {
      setChangeRequest({
        ...changeRequest,
        ['subtopic']: event.target.value
      });
    }
  };

  const handleDetailsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (changeRequest) {
      setChangeRequest({
        ...changeRequest,
        ['reqDetails']: event.target.value
      });
    }
  };

  const setSubtopicsForTopic = (topicName: string) => {
    const selectedTopicObject = topics.find((topic) => topic['Topic'] === topicName);
    setSubtopics(selectedTopicObject ? selectedTopicObject['Subtopic'] : []);
  };

  const onSubmit = (values: FormValues) => {
    if (changeRequest) {
      setChangeRequest({
        ...changeRequest,
        ['reservationId']: reservationId,
        ['topic']: values.topic,
        ['subtopic']: values.subtopic
      });
      setIsSubmitting(true);
      if (mode === modes.ADD) {
        createRequest(reservationId, changeRequest, validatedPinNumber)
          .then((response) => {
            if (response.status === 429) {
              onAlertHandler(true, 'TooManyRequestError');
            } else if (response.status === 400 || response.status === 500) {
              onAlertHandler(true, 'Error');
            } else {
              onAlertHandler(true, 'Success');
              hideComponent(true);
            }
          })
          .catch(() => {
            onAlertHandler(true, 'Error');
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        updateRequest(changeRequest, validatedPinNumber)
          .then(() => {
            onAlertHandler(true, 'Success');
            hideComponent(true);
          })
          .catch(() => {
            onAlertHandler(true, 'Error');
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    }
  };

  const initialValues = {
    topic: changeRequest ? changeRequest.topic : '',
    subtopic: changeRequest ? changeRequest.subtopic : '',
    reqDetails: changeRequest ? changeRequest.reqDetails : ''
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (values.topic === '') {
      errors.topic = 'You must select a topic';
    }
    if (values.subtopic === '') {
      errors.subtopic = 'You must select a subtopic';
    }
    if (values.reqDetails === '') {
      errors.reqDetails = 'You must provide details of this change request';
    }
    return errors;
  };

  return (
    <div>
      <Helmet>
        <title>{`${reformatReservationId(
          reservationId
        )} - Reservation details • Booking.com`}</title>
      </Helmet>
      <Card className={styles.reservationPropertyDetailsOuterContainer}>
        <h1>{mode === 'Edit' ? `Edit` : `Create new`} change request</h1>
        <span>
          Requests from customers and partners can be recorded here, but no changes can be made to
          reservations.
        </span>

        <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
          {({ errors, touched, handleChange, handleSubmit }) => (
            <div>
              <form className={styles.reservationInputForm} onSubmit={handleSubmit}>
                <FormSelectInput
                  label={'topic'}
                  id={'topic'}
                  name={'Topic'}
                  placeholder={'Select topic'}
                  value={changeRequest?.topic}
                  onChange={handleChange}
                  handleChange={handleTopicChange}
                  errorType={errors.topic}
                  touched={touched.topic}
                  values={topics.map((item) => item['Topic'])}
                />

                <FormSelectInput
                  label={'subtopic'}
                  id={'subtopic'}
                  name={'Subtopic'}
                  placeholder={'Select subtopic'}
                  value={changeRequest?.subtopic}
                  onChange={handleChange}
                  handleChange={handleSubTopicChange}
                  errorType={errors.subtopic}
                  touched={touched.subtopic}
                  values={subtopics}
                />

                <FormTextInput
                  label={'reqDetails'}
                  id={'reqDetails'}
                  name={'Request details'}
                  placeholder={'Provide as much detail about the request as possible...'}
                  value={changeRequest?.reqDetails}
                  onChange={handleChange}
                  handleChange={handleDetailsChange}
                  errorType={errors.reqDetails}
                  touched={touched.reqDetails}
                />

                <div className={styles.rowFlex}>
                  <Button
                    className={styles.changeRequestSaveButton}
                    disabled={isSubmitting}
                    type={'submit'}>
                    Save change request
                  </Button>
                  <button
                    ref={selectRef}
                    className={styles.changeRequestCancelButton}
                    type={'button'}
                    onClick={() => hideComponent(true)}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default ChangeRequest;
