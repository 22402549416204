import { Alert } from '@bookingcom/bui-react';
import styles from './BookingAlert.module.css';

const BookingAlert = () => (
  <Alert
    className={styles.bookingAlertContainer}
    title="There was a problem"
    text="Sorry, we could not return any results due to technical issues. Please try again in a few minutes."
    variant="error"
  />
);

export default BookingAlert;
