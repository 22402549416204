import React from 'react';
import styles from './PropertyDetails.module.css';
import Card from '../UI/card/Card';
import VectorError from '../../assets/images/Vector.png';

type propertyDetails = {
  hotelName: string;
  hotelId: string;
  callbackNumber: Array<string>;
};

interface IPropertyDetails {
  propertyDetails: propertyDetails;
}

const PropertyDetails = ({ propertyDetails }: IPropertyDetails) => {
  return (
    <Card className={styles.propertyDetailsContainer}>
      <h2>
        <strong className={styles.propertyDetailsText}>Property Details</strong>
      </h2>
      <div className={styles.propertyDetailsNotification}>
        <img className={styles.vectorImg} src={VectorError} alt={'errorInput'} />
        <p>
          If you are speaking to someone who claims to represent this property, you must call them
          back on the displayed phone number before sharing any further information with them.
        </p>
      </div>

      <div className={styles.hotelDetailsContainer}>
        <div className={styles.hotelLabelContainer}>
          <p className={styles.labelText}>Hotel ID:</p>
        </div>
        <div>
          <p>{propertyDetails.hotelId}</p>
        </div>
      </div>

      <div className={styles.hotelDetailsContainer}>
        <div className={styles.hotelLabelContainer}>
          <p className={styles.labelText}>Name:</p>
        </div>
        <div>
          <p>{propertyDetails.hotelName}</p>
        </div>
      </div>

      <div className={styles.hotelDetailsContainer}>
        <div className={styles.hotelLabelContainer}>
          <p className={styles.labelText}>Telephone number(s):</p>
        </div>

        <div>
          {propertyDetails.callbackNumber.map((number, index) => (
            <p key={index}>{number}</p>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default PropertyDetails;
