import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import styles from './Landing.module.css';
import SearchReservation from '../../components/searchReservation/SearchReservation';
import PropertyDetails from '../../components/propertyDetails/PropertyDetails';
import { useOktaAuth } from '@okta/okta-react';
import Spinner from '../../components/UI/spinner/Spinner';
import Header from '../../components/header/Header';
import AppInfo from '../../components/appInfo/AppInfo';
import ReservationTable from '../../components/reservationTable/ReservationTable';
import { IApiParametersValues } from '../../interfaces';
import { getCsAgentBookingReservationDetails } from '../../apiCall';
import BookingAlert from '../../components/UI/bookingAlert/BookingAlert';
import NoReservation from '../../components/noReservation/NoReservation';
import { trackPromise } from 'react-promise-tracker';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import ReservationContext from '../../context/ReservationContext';
import { paginationConstants } from '../../utils/contants';

const Landing = () => {
  const landingPathName = useLocation().pathname;
  const [reservationDetails, setReservationDetails] = useState(Object);
  const [showPropertyDetailsFlag, setShowPropertyDetailsFlag] = useState(false);
  const [showGetReservationDetailsErrorFlag, setShowGetReservationDetailsErrorFlag] =
    useState(false);
  const [showNoReservationFlag, setShowNoReservationFlag] = useState(false);
  const { oktaAuth, authState } = useOktaAuth();
  const [reservationSearchValues, setReservationSearchValues] = useState({
    reservationId: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    hotelId: '',
    checkInDate: '',
    checkOutDate: '',
    pageNumber: paginationConstants.pageNumber,
    sortOrder: '',
    sortField: '',
    pageLimit: paginationConstants.pageLimit
  });
  useEffect(() => {
    (async () => {
      if (authState?.isAuthenticated === false) {
        await oktaAuth.signInWithRedirect({});
      }
    })();
  }, [oktaAuth, authState?.isAuthenticated]);
  const getBookingReservationDetailsHandler = async (values: IApiParametersValues) => {
    setReservationSearchValues({
      reservationId: values.reservationId,
      emailAddress: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      hotelId: values.hotelId,
      checkInDate: values.checkInDate as string,
      checkOutDate: values.checkOutDate as string,
      pageNumber: values.pageNumber,
      sortOrder: values.sortOrder,
      sortField: values.sortField,
      pageLimit: values.pageLimit
    });
    setShowNoReservationFlag(false);
    setShowGetReservationDetailsErrorFlag(false);
    setShowPropertyDetailsFlag(false);
    await trackPromise(
      getCsAgentBookingReservationDetails(values)
        .then((response) => {
          if (response.responseCode === 200) {
            if (response.reservationDetails.length > 0) {
              values.hotelId && setShowPropertyDetailsFlag(true);
              const state = {
                response: response.reservationDetails,
                totalNumberOfResults: response.totalNumberOfResults,
                hotelDetail: response.hotelDetail
              };
              setReservationDetails(state);
            } else {
              setShowNoReservationFlag(true);
            }
          } else if (response.responseCode === 404) {
            setShowNoReservationFlag(true);
          } else {
            setShowGetReservationDetailsErrorFlag(true);
          }
        })
        .catch((error) => {
          error && setShowGetReservationDetailsErrorFlag(true);
        })
    );
  };
  if (!authState?.isAuthenticated) {
    return (
      <div data-testid="csAgentLoadingTestId">
        <p>.....Loading</p>
        <Spinner />
      </div>
    );
  }
  return (
    <ReservationContext.Provider value={reservationSearchValues}>
      <div data-testid={'landingTestId'} className={styles.landingOuterContainer}>
        <Helmet>
          <title>{`Search • Booking.com`}</title>
        </Helmet>
        <header>
          <Header />
          <AppInfo />
        </header>
        <div className={styles.landingContainer}>
          <Sidebar csAgentPathName={landingPathName} changeRequestButtonDisabled={true} />
          <div className={styles.reservationContainer}>
            <SearchReservation
              getBookingReservationDetailsHandler={getBookingReservationDetailsHandler}
            />
            {showPropertyDetailsFlag && (
              <PropertyDetails propertyDetails={reservationDetails.hotelDetail} />
            )}
            {showGetReservationDetailsErrorFlag && <BookingAlert />}
            {showNoReservationFlag && <NoReservation />}
            {!showGetReservationDetailsErrorFlag &&
              !showNoReservationFlag &&
              reservationDetails.totalNumberOfResults > 0 && (
                <ReservationTable
                  reservationDetails={reservationDetails}
                  getReservationTableDetailsHandler={getBookingReservationDetailsHandler}
                />
              )}
          </div>
        </div>
      </div>
    </ReservationContext.Provider>
  );
};

export default Landing;
