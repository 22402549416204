import React from 'react';
import styles from './AlertContent.module.css';
import classNames from 'classnames';
import VectorError from '../../assets/images/VectorError.png';
import VectorSuccess from '../../assets/images/VectorSuccess.png';

interface IAdminToolAlert {
  alertType: string;
  mode: string;
}

interface IAlertContent {
  alertContainerStyle: string;
  imgAltText: string;
  headerText: string;
  contentText: string;
}

const AlertBody = ({ alertContainerStyle, imgAltText, headerText, contentText }: IAlertContent) => {
  let imgSrc;
  if (imgAltText === 'Success') {
    imgSrc = VectorSuccess;
  } else if (imgAltText === 'Error') {
    imgSrc = VectorError;
  }
  return (
    <div className={alertContainerStyle}>
      <img className={styles.VectorIconImg} src={imgSrc} alt={imgAltText} />
      <div className={styles.adminToolAlertContentContainer}>
        <p>
          <strong className={styles.alertContentHeaderText}>{headerText}</strong>
        </p>
        <p className={styles.alertContentText}>{contentText}</p>
      </div>
    </div>
  );
};

const successContent = (headerText: string, contentText: string) => ({
  alertContainerStyle: classNames(
    styles.adminToolAlertContainer,
    styles.adminToolSuccessAlertStyle
  ),
  imgAltText: 'Success',
  headerText,
  contentText
});

const errorContent = {
  alertContainerStyle: classNames(styles.adminToolAlertContainer, styles.adminToolErrorAlertStyle),
  imgAltText: 'Error',
  headerText: 'Change request could not be saved',
  contentText:
    'Sorry, there was a problem saving the details of this change request. Please try again.'
};

const errorTooManyRequestContent = {
  alertContainerStyle: classNames(styles.adminToolAlertContainer, styles.adminToolErrorAlertStyle),
  imgAltText: 'Error',
  headerText: 'Change request could not be saved',
  contentText:
    'Sorry, Too many request has been created. Please try again after some time.'
};

const AlertContentProps: Record<string, IAlertContent> = {
  AddSuccess: successContent(
    'Change request added',
    'Your change request details have been saved successfully.'
  ),
  EditSuccess: successContent(
    'Change request updated',
    'Your change request details have been updated successfully.'
  ),
  AddError: errorContent,
  EditError: errorContent,
  AddTooManyRequestError: errorTooManyRequestContent
};

const AlertContent = ({ alertType, mode }: IAdminToolAlert) => {
  return <AlertBody {...AlertContentProps[mode + alertType]} />;
};

export default AlertContent;
