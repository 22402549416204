import React from 'react';
import styles from './Sidebar.module.css';
import Card from '../UI/card/Card';
import Button from '../UI/button/Button';
import SignOut from '../../assets/images/Sign-out.png';
import SearchIcon from '../../assets/images/SearchIcon.png';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate, useParams } from 'react-router-dom';

interface ISidebar {
  csAgentPathName: string;
  onCRButtonClick?: () => void;
  changeRequestButtonDisabled?: boolean;
}
const Sidebar = ({ csAgentPathName, onCRButtonClick, changeRequestButtonDisabled }: ISidebar) => {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const { reservationId } = useParams();

  return (
    <Card className={styles.sidebarContainer}>
      <Button
        className={styles.changeRequestButton}
        type={'button'}
        disabled={changeRequestButtonDisabled}
        onClick={onCRButtonClick}>
        Create change request
      </Button>
      <div>
        {csAgentPathName === `/reservation/${reservationId}` && (
          <Button className={styles.logOutButton} type={'button'} onClick={() => navigate('/')}>
            <img src={SearchIcon} alt={'Search icon'} />
            Back to search
          </Button>
        )}
        <Button
          className={styles.logOutButton}
          type={'button'}
          onClick={async () => {
            await oktaAuth.signOut();
          }}>
          <img src={SignOut} alt={'sign out'} />
          Log out
        </Button>
      </div>
    </Card>
  );
};

export default Sidebar;
