import React from 'react';
import styles from './Details.module.css';

interface IDetails {
  label: string;
  value: string;

  className?: string;
}

const Details = ({ label, value, className }: IDetails) => {
  return (
    <div className={styles.detailsContainer}>
      <div className={styles.detailsLabelContainer}>
        <p className={styles.labelText}>{label}</p>
      </div>
      <div className={styles.detailsValueContainer}>
        <p className={className}>{value}</p>
      </div>
    </div>
  );
};

export default Details;
