import React from 'react';
import styles from './ReservationPropertyDetails.module.css';
import Card from '../UI/card/Card';
import Details from '../details/Details';
import {
  reformatReservationId,
  getFullMonth,
  getYear,
  getDay,
  getDate,
  languageMapping
} from '../../utils/utils';
import { IreservationDetails } from '../../interfaces';
import ReservationPin from '../reservationPin/ReservationPin';
interface IReservationPropertyDetails {
  details: IreservationDetails;
  isPinCodeCorrectHandler: (flag: boolean, pinNumber: string) => void;
}

const ReservationPropertyDetails = ({
  details,
  isPinCodeCorrectHandler
}: IReservationPropertyDetails) => {
  return (
    <Card className={styles.reservationPropertyDetailsOuterContainer}>
      <ReservationPin
        reservationId={details.reservationId}
        isPinCodeCorrectHandler={(flag, pinNumber) => isPinCodeCorrectHandler(flag, pinNumber)}
      />
      <div
        className={styles.reservationPropertyDetailsContainer}
        data-testid={'reservationPropertyRoomDetailsTestId'}>
        <div className={styles.reservationDetails}>
          <h3>
            <strong>Reservation details</strong>
          </h3>
          <Details
            label={'Confirmation number:'}
            value={reformatReservationId(details.reservationId)}
          />
          <Details
            label={'Status:'}
            className={styles.statusValueText}
            value={details.reservationstatus}
          />
          {/*Note:Will be uncommented once detailed requirements comes for it*/}
          {/*<Details label={'Price:'} value={`${details.currencyCode} ${details.reservationPrice}`} />*/}
          {/*<Details label={'Tax:'} value={`${details.currencyCode} ${NO_INFO_AVAILABLE}`} />*/}
          <Details
            label={'Check-in:'}
            value={`${getDay(details.checkInDate)}, ${getDate(details.checkInDate)} ${getFullMonth(
              details.checkInDate
            )} ${getYear(details.checkInDate)}`}
          />
          <Details
            label={'Check-out:'}
            value={`${getDay(details.checkOutDate)}, ${getDate(
              details.checkOutDate
            )} ${getFullMonth(details.checkOutDate)} ${getYear(details.checkOutDate)}`}
          />
          <Details label={'Booker email:'} value={details.bookerEmail} />
          {/*Note:Will be uncommented once detailed requirements comes for it*/}
          {/*<Details label={'Phone:'} value={details.bookerPhNo} />*/}
          <Details label={'Language:'} value={languageMapping(details.guestLanguage) as string} />
        </div>
        <div className={styles.propertyDetails}>
          <h3>
            <strong>Property details</strong>
          </h3>
          <Details label={'Hotel ID:'} value={details.propertyDetails.hotelId} />
          <Details label={'Name:'} value={details.propertyDetails.hotelName} />
          <Details label={'Phone:'} value={details.propertyDetails.hotelphoneNumber} />
          {/*Note:Will be uncommented once detailed requirements comes for it*/}
          {/*<Details label={'Email:'} value={details.propertyDetails.hotelEmailaddress} />*/}
          {/*<Details label={'Languages:'} value={details.propertyDetails.propertyLanguage} />*/}
          {/*<Details*/}
          {/*  label={'Payment:'}*/}
          {/*  value={details.propertyDetails.acceptedPaymentMethodsAtTheProperty}*/}
          {/*/>*/}
          {/*<Details label={'Payment methods:'} value={details.propertyDetails.paymentMethods} />*/}
          {/*<Details label={'Address:'} value={details.propertyDetails.hotelPhysicalAddress} />*/}
          {/*<Details label={'Check-in:'} value={details.propertyDetails.checkinTimesAtProperty} />*/}
          {/*<Details label={'Pet policy:'} value={details.propertyDetails.petAndChildrenPolicy} />*/}
          {/*<Details label={'Child policy:'} value={details.propertyDetails.petAndChildrenPolicy} />*/}
          {/*<Details*/}
          {/*  label={'Cancellation policy:'}*/}
          {/*  value={details.propertyDetails.cancellationPolicyFees}*/}
          {/*/>*/}
        </div>
        <div className={styles.roomDetails}>
          <h3>
            <strong>Room details</strong>
          </h3>
          <br></br>
          <p>
            <strong>Room 1</strong>
          </p>
          <br></br>
          <Details label={'Name:'} value={details.guestName} />
          {/*Note:Will be uncommented once detailed requirements comes for it*/}
          {/*<Details label={'Occupancy:'} value={NO_INFO_AVAILABLE} />*/}
          {/*<Details label={'Special requests:'} value={NO_INFO_AVAILABLE} />*/}
        </div>
      </div>
    </Card>
  );
};

export default ReservationPropertyDetails;
