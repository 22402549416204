import { useEffect, useState } from 'react';
import { getChangeRequests } from '../../apiCall';
import { IChangeRequest } from '../../interfaces';
import { reformatTimestamp } from '../../utils/utils';
import styles from './ChangeRequestList.module.css';
import Card from '../UI/card/Card';
import Button from '../UI/button/Button';
import arrowNav from '../../assets/images/ArrowNav.svg';

interface IChangeRequestListDetails {
  onEditHandler: (request: IChangeRequest) => void;
  reservationId: string;
  renderagain: boolean;
  isPinCodeCorrect: boolean;
}

const ChangeRequestList = ({
  onEditHandler,
  reservationId,
  renderagain,
  isPinCodeCorrect
}: IChangeRequestListDetails) => {
  const [crequests, setcrequests] = useState<IChangeRequest[]>([]);
  const [filteredRequests, setFilteredRequests] = useState<IChangeRequest[]>([]);
  const [showOlderRequestsLink, setShowOlderRequestsLink] = useState(false);
  useEffect(() => {
    getChangeRequests(reservationId)
      .then((response) => {
        response.sort((a: IChangeRequest, b: IChangeRequest) => {
          const dateA = new Date(a.lastModifiedDate ? a.lastModifiedDate : a.createdDate);
          const dateB = new Date(b.lastModifiedDate ? b.lastModifiedDate : b.createdDate);
          return dateB.getTime() - dateA.getTime();
        });
        setcrequests(response);
        if (response.length > 10) {
          setFilteredRequests(response.slice(0, 10));
          setShowOlderRequestsLink(true);
        } else {
          setFilteredRequests(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [renderagain, reservationId]);

  const showFullReqList = () => {
    setShowOlderRequestsLink(false);
    setFilteredRequests(crequests);
  };

  return (
    <Card className={styles.changeRequestsListOuterContainer}>
      <h3>
        <strong>Change requests</strong>
      </h3>
      {filteredRequests.length > 0 ? (
        <span>
          This list shows all of the change requests captured by agents. They are listed in reverse
          chronological order, i.e. the most recent change appears at the top.
        </span>
      ) : (
        <span>No requests have been created.</span>
      )}
      {filteredRequests.length > 0 && (
        <div>
          <ul className={styles.ulist}>
            {filteredRequests.map((d) => (
              <li key={d.requestId}>
                <div className={styles.requestContainer}>
                  <div className={styles.requestContent} key={d.requestId}>
                    <div className={styles.requestHeader}>
                      <b>
                        {d.topic} <img src={arrowNav} alt={'arrowNav'}></img> {d.subtopic}
                      </b>
                    </div>
                    <div className={styles.requestBody}>{d.reqDetails}</div>
                    <div className={styles.requestFooter}>
                      Created {`${reformatTimestamp(d.createdDate)}`} by <b>@{d.createdBy} . </b>
                      {d.lastModifiedDate && (
                        <span>
                          {' '}
                          Last updated {`${reformatTimestamp(d.lastModifiedDate)}`} by{' '}
                          <b>@{d.lastModifiedBy}</b>
                        </span>
                      )}
                    </div>
                  </div>
                  <button
                    disabled={isPinCodeCorrect}
                    className={styles.editButton}
                    onClick={() => onEditHandler(d)}>
                    Edit change request
                  </button>
                </div>
              </li>
            ))}
          </ul>
          {showOlderRequestsLink && (
            <div className={styles.componentFooter}>
              <Button className={styles.showMoreButton} type={'button'} onClick={showFullReqList}>
                Show older change requests
              </Button>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default ChangeRequestList;
