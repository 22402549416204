import { IApiParametersValues, IChangeRequest, IReservationIdFormValues } from './interfaces';
import { getAccessTokenHandler, getEpochFromDate } from './utils/utils';

const getCsAgentBookingReservationDetailsApi = process.env
  .REACT_APP_GET_CS_AGENT_RESERVATION_BOOKING_DETAILS_API as string;

const getCsTopics = process.env.REACT_APP_GET_CS_AGENT_REQUESTS_API as string;
const postRequest = process.env.REACT_APP_GET_CS_AGENT_REQUESTS_CREATE_API as string;
const postCheckReservationPin = process.env.REACT_APP_POST_CS_AGENT_CHECK_PIN_API as string;
export const getCsAgentBookingReservationDetails = async (values: IApiParametersValues) => {
  try {
    const response = await fetch(getCsAgentBookingReservationDetailsApi, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessTokenHandler().accessToken}`
      },
      body: JSON.stringify({
        reservationId: values.reservationId.toString().replace(/\s/g, '').replace(/\./g, ''),
        bookerLastName: values.lastName,
        bookerFirstName: values.firstName,
        checkinDate: values.checkInDate && getEpochFromDate(new Date(values.checkInDate)),
        checkoutDate: values.checkOutDate && getEpochFromDate(new Date(values.checkOutDate)),
        hotelId: values.hotelId,
        bookerEmail: values.emailAddress,
        pageNumber: values.pageNumber,
        sortOrder: values.sortOrder,
        sortField: values.sortField,
        pageLimit: values.pageLimit
      })
    });
    return response.json();
  } catch (error) {
    return (error as Error).message;
  }
};

export const getReservationDetailsByReservationId = async (values: IReservationIdFormValues) => {
  try {
    const response = await fetch(getCsAgentBookingReservationDetailsApi, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessTokenHandler().accessToken}`
      },
      body: JSON.stringify({
        reservationId: values.reservationId.toString().replace(/\s/g, '').replace(/\./g, ''),
        bookerEmail: values.bookerEmail
      })
    });
    return response.json();
  } catch (error) {
    return (error as Error).message;
  }
};

export const getTopics = async () => {
  try {
    const response = await fetch(getCsTopics, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessTokenHandler().accessToken}`
      }
    });
    return response.json();
  } catch (error) {
    return (error as Error).message;
  }
};

export const createRequest = async (
  reservationID: string,
  changeRequest: IChangeRequest,
  pinNumber: string
) => {
  try {
    return await fetch(postRequest, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessTokenHandler().accessToken}`
      },
      body: JSON.stringify({
        reservationId: reservationID,
        topic: changeRequest.topic,
        subtopic: changeRequest.subtopic,
        reqDetails: changeRequest.reqDetails,
        pinCode: pinNumber
      })
    });
  } catch (error) {
    throw new Error('Error');
  }
};

export const updateRequest = async (changeRequest: IChangeRequest, pinNumber: string) => {
  try {
    const response = await fetch(
      `${postRequest}/${changeRequest.reservationId}/${changeRequest.requestId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${getAccessTokenHandler().accessToken}`
        },
        body: JSON.stringify({
          requestId: changeRequest.requestId,
          reservationId: changeRequest.reservationId,
          topic: changeRequest.topic,
          subtopic: changeRequest.subtopic,
          reqDetails: changeRequest.reqDetails,
          pinCode: pinNumber
        })
      }
    );
    return response.json();
  } catch (error) {
    throw new Error('Error');
  }
};

export const getChangeRequests = async (reservationID: string) => {
  const response = await fetch(`${postRequest}/${reservationID}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessTokenHandler().accessToken}`
    }
  });
  if (response.status === 200) {
    return response.json();
  } else {
    throw new Error('Error');
  }
};

export const checkReservationPin = async (reservationId: string, pinNumber: string) => {
  try {
    const response = await fetch(postCheckReservationPin, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessTokenHandler().accessToken}`
      },
      body: JSON.stringify({
        reservationId: reservationId,
        pinCode: pinNumber
      })
    });
    return response.json();
  } catch (error) {
    throw new Error('Internal server error');
  }
};
