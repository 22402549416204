import React, { useState } from 'react';
import { Table, Actionable, Stack, Icon } from '@bookingcom/bui-react';
import { ArrowDownIcon, ArrowUpIcon } from '@bookingcom/bui-assets-react/streamline';
import {
  getDateFormat,
  getDateFormatFromTimeStamp,
  reformatReservationId,
  sortFieldFormat,
  tableHeaderFormat,
  reformatUsernameField
} from '../../../utils/utils';
import styles from './SortTable.module.css';
import { IreservationDetails } from '../../../interfaces';
import { tableHeadingKeyMap } from '../../../utils/contants';
import cryptoJS from 'crypto-js';

interface ISortTable {
  reservationDetails: {
    response: Array<IreservationDetails>;
  };
  sortReservationTableHandler: (key: string, order: string) => void;
}

interface ISingleReservationDetails {
  reservationId: string;
  name: {
    bookerName: string;
    bookerEmail: string;
  };
  propertyDetails: {
    hotelName: string;
    address: string;
  };
  checkInDate: string;
  checkOutDate: string;
  bookedOn: string;
}

const SortTable = ({ reservationDetails, sortReservationTableHandler }: ISortTable) => {
  //true => ascending , false => descending
  const [tableSortHeading, setTableSortHeading] = useState({
    confirmationNumber: true,
    name: true,
    property: true,
    checkIn: true,
    checkOut: true,
    bookedOn: true
  });
  const handleSwitch = (key: string) => {
    const tableHeadingFieldKey = tableHeadingKeyMap[key as keyof typeof tableHeadingKeyMap];
    if (tableHeadingFieldKey) {
      setTableSortHeading({
        ...tableSortHeading,
        [tableHeadingFieldKey]:
          !tableSortHeading[tableHeadingFieldKey as keyof typeof tableSortHeading]
      });
      sortReservationTableHandler(
        tableHeaderFormat(key),
        sortFieldFormat(!tableSortHeading[tableHeadingFieldKey as keyof typeof tableSortHeading])
      );
    }
  };
  const rows = reservationDetails.response.map((reservation: IreservationDetails) => {
    const reservationDetail: ISingleReservationDetails = {
      reservationId: reservation.reservationId,
      name: {
        bookerName: reformatUsernameField(reservation.bookerFirstName, reservation.bookerLastName),
        bookerEmail: reservation.bookerEmail
      },
      propertyDetails: {
        hotelName: `${reservation.propertyDetails.hotelName} | ${reservation.propertyDetails.hotelId}`,
        address: reservation.propertyDetails.hotelPhysicalAddress
      },
      checkInDate: getDateFormat(reservation.checkInDate),
      checkOutDate: getDateFormat(reservation.checkOutDate),
      bookedOn: reservation.createdOn
    };
    const details = [];
    let key: keyof ISingleReservationDetails;
    for (key in reservationDetail) {
      let item = {};
      if (key === 'reservationId') {
        const encryptedEmailId = cryptoJS.AES.encrypt(
          reservationDetail.name.bookerEmail,
          process.env.REACT_APP_SECRET_KEY_FOR_TEXT as string
        ).toString();
        const encodedEmailIdCyphertext = encodeURIComponent(encryptedEmailId);
        item = {
          content: (
            <a
              href={`/reservation/${reservationDetail[key]}/${encodedEmailIdCyphertext}`}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#0071C2', textDecoration: 'none' }}>
              {reformatReservationId(reservationDetail[key])}
            </a>
          )
        };
      } else if (key === 'name') {
        item = {
          content: (
            <>
              <p>
                <strong>{reservationDetail[key].bookerName}</strong>
              </p>
              <p className={styles.bookerEmailText}>{reservationDetail[key].bookerEmail}</p>
            </>
          )
        };
      } else if (key === 'propertyDetails') {
        item = {
          content: (
            <>
              <p>
                <strong>{reservationDetail[key].hotelName}</strong>
              </p>
              <p className={styles.addressContentText}>{reservationDetail[key].address}</p>
            </>
          )
        };
      }
      else if (key === 'bookedOn') {
        item = {
          content: (
            <>
              <p>{getDateFormatFromTimeStamp(reservationDetail[key]).bookedDate}</p>
              <p className={styles.childContentText}>
                {getDateFormatFromTimeStamp(reservationDetail[key]).bookedTime}
              </p>
            </>
          )
        };
      } else {
        item = {
          content: reservationDetail[key]
        };
      }
      details.push(item);
    }
    return {
      cells: details
    };
  });
  return (
    <div data-testid={'sortTableTestId'}>
      <Table
        className={styles.sortTableContainer}
        headings={[
          {
            content: (
              <Stack direction={'row'} gap={1} alignItems="start">
                <Actionable
                  className={styles.sortTableHeading}
                  onClick={() => handleSwitch('Confirmation number')}>
                  <Stack.Item>Confirmation number</Stack.Item>
                  <Icon
                    size={'smaller'}
                    color={'disabled'}
                    svg={tableSortHeading.confirmationNumber ? ArrowUpIcon : ArrowDownIcon}
                  />
                </Actionable>
              </Stack>
            ),
            width: '180px',
            align: 'start'
          },
          {
            content: (
              <Stack>
                <Actionable
                  className={styles.sortTableHeading}
                  onClick={() => handleSwitch('Name')}>
                  <Stack.Item>Name</Stack.Item>
                </Actionable>
              </Stack>
            ),
            align: 'start'
          },
          {
            content: (
              <Stack alignItems="start">
                <Actionable
                  className={styles.sortTableHeading}
                  onClick={() => handleSwitch('Property')}>
                  <Stack.Item>Property</Stack.Item>
                </Actionable>
              </Stack>
            ),
            align: 'start'
          },
          {
            content: (
              <Stack alignItems="start">
                <Actionable
                  className={styles.sortTableHeading}
                  onClick={() => handleSwitch('Check-in date')}>
                  <Stack.Item>Check-in date</Stack.Item>
                </Actionable>
              </Stack>
            ),
            align: 'start'
          },
          {
            content: (
              <Stack alignItems="start">
                <Actionable
                  className={styles.sortTableHeading}
                  onClick={() => handleSwitch('Check-out date')}>
                  <Stack.Item>Check-out date</Stack.Item>
                </Actionable>
              </Stack>
            ),
            align: 'start'
          },
          {
            content: (
              <Stack alignItems="start">
                <Actionable
                  className={styles.sortTableHeading}
                  onClick={() => handleSwitch('Booked on')}>
                  <Stack.Item>Booked on</Stack.Item>
                </Actionable>
              </Stack>
            ),
            align: 'start'
          }
        ]}
        rows={rows}
        compact={true}
      />
    </div>
  );
};
export default SortTable;
