import React from 'react';

import styles from './FormTextInput.module.css';
import { ErrorMessage } from 'formik';

interface ISelectProps {
  label: string;
  id: string;
  name: string;
  placeholder: string;
  value: string | undefined;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  errorType: string | undefined;
  touched: boolean | undefined;
}

const FormTextInput = (props: ISelectProps) => {
  return (
    <div className={styles.formInput} data-testid="formInputTestId">
      <div className={styles.requestDetails}>
        <div className={styles.requestDetailsHeader}>
          <label>{props.name}</label>
        </div>
        <div>
          <textarea
            maxLength={1000}
            style={props.errorType ? { border: '1px solid red' } : {}}
            id={props.id}
            className={styles.requestTextArea}
            rows={10}
            cols={100}
            data-testid={props.id + '_testId'}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              props.onChange(e);
              props.handleChange(e);
            }}></textarea>
        </div>
      </div>
      <ErrorMessage name={props.id} component="div" className={styles.invalidInput} />
    </div>
  );
};

export default FormTextInput;
