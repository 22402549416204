export const serviceConstants = {
  OKTA_TOKEN_KEY: 'okta-token-storage'
};

export const NO_INFO_AVAILABLE = 'No information available';

export const reservationStatus = {
  COMPLETED_BOOKING_OK: {
    key: 1,
    status: 'ok'
  },
  COMPLETED_BOOKING_CANCELLED_BY_GUEST: {
    key: 0,
    status: 'cancelled by guest'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_FRAUDULENT: {
    key: -2,
    status: 'cancelled by hotel (fraudulent)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_NO_CC_AT_ALL: {
    key: -4,
    status: 'cancelled by hotel (no cc at all)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_OVERBOOKING: {
    key: -5,
    status: 'cancelled by hotel (overbooking)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_HOTEL_CLOSED: {
    key: -6,
    status: 'cancelled by hotel (hotel closed)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_OTHER_REASON: {
    key: -7,
    status: 'cancelled by hotel (other reason)'
  },
  COMPLETED_BOOKING_NO_SHOW: {
    key: -8,
    status: 'no show'
  },
  COMPLETED_BOOKING_CANCELLED_REASON_UNKNOWN_OLD_SYSTEM: {
    key: -9,
    status: 'cancelled, reason unknown (probably from old system)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_INVALID_CREDIT_CARD: {
    key: -10,
    status: 'cancelled by hotel (invalid credit card)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_RESERVATION_STATEMENT: {
    key: -11,
    status: 'cancelled by hotel (reservation statement)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_BOOKINGS_TEST_BOOKING: {
    key: -12,
    status: 'deleted by BOOKING (test booking)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_BOOKINGS_FRAUDULENT: {
    key: -13,
    status: 'cancelled by BOOKING (fraudulent)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_GUEST_WAIVED_FEES: {
    key: -14,
    status: 'cancelled by guest (waived fees)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_GUEST_FORCED_CIRCUMSTANCES: {
    key: -17,
    status: 'cancelled by guest (forced circumstances)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_GUEST_PARTIAL_FEES: {
    key: -18,
    status: 'cancelled by guest (partially waived fees)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_BOOKING_BVERIFIED: {
    key: -19,
    status: 'cancelled by BOOKING (B.Verified)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_OVERBOOKING_WAIVED_COMMISSION: {
    key: -20,
    status: 'cancelled by hotel (waived commission - guest relocated)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_BOOKINGS_INVALID_PAYMENT: {
    key: -21,
    status: 'cancelled by BOOKING (unsuccessful payment)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_UNPAID_DEPOSIT: {
    key: -22,
    status: 'cancelled by hotel (unpaid deposit)'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_OVERBOOKING_COMMISSIONABLE: {
    key: -23,
    status: 'cancelled by hotel (commissionable cancellation)'
  },
  COMPLETED_BOOKING_NO_SHOW_WAIVED_FEES: {
    key: -24,
    status: 'no show'
  },
  COMPLETED_BOOKING_CANCELLED_BY_HOTEL_OTHER_REASON_WAIVED_FEES: {
    key: -25,
    status: 'cancelled by hotel - waived fees (other reason)'
  }
};

export const paginationConstants = {
  //"sortOrder":"ASCENDING", //can have values as  DESCENDING or ASCENDING
  //"sortField": "price" // can have values as hotelReservationId,bookerName,checkin,hotelName,checkout,created,price
  pageNumber: '1',
  sortOrder: 'ASCENDING',
  sortField: 'hotelReservationId',
  pageLimit: '10'
};

export const tableHeadingKeyMap = {
  'Confirmation number': 'confirmationNumber',
  Name: 'name',
  Property: 'property',
  'Check-in date': 'checkIn',
  'Check-out date': 'checkOut',
  Price: 'price',
  'Booked on': 'bookedOn'
};

export const modes = {
  ADD: 'Add',
  EDIT: 'Edit'
};

export const langCodes = {
  af: 'Afrikaans',
  sq: 'Albanian',
  ar: 'Arabic (Standard)',
  'ar-dz': 'Arabic (Algeria)',
  'ar-bh': 'Arabic (Bahrain)',
  'ar-eg': 'Arabic (Egypt)',
  'ar-iq': 'Arabic (Iraq)',
  'ar-jo': 'Arabic (Jordan)',
  'ar-kw': 'Arabic (Kuwait)',
  'ar-lb': 'Arabic (Lebanon)',
  'ar-ly': 'Arabic (Libya)',
  'ar-ma': 'Arabic (Morocco)',
  'ar-om': 'Arabic (Oman)',
  'ar-qa': 'Arabic (Qatar)',
  'ar-sa': 'Arabic (Saudi Arabia)',
  'ar-sy': 'Arabic (Syria)',
  'ar-tn': 'Arabic (Tunisia)',
  'ar-ae': 'Arabic (U.A.E.)',
  'ar-ye': 'Arabic (Yemen)',
  an: 'Aragonese',
  hy: 'Armenian',
  as: 'Assamese',
  ast: 'Asturian',
  az: 'Azerbaijani',
  eu: 'Basque',
  bg: 'Bulgarian',
  be: 'Belarusian',
  bn: 'Bengali',
  bs: 'Bosnian',
  br: 'Breton',
  my: 'Burmese',
  ca: 'Catalan',
  ch: 'Chamorro',
  ce: 'Chechen',
  zh: 'Chinese',
  'zh-hk': 'Chinese (Hong Kong)',
  'zh-cn': 'Chinese (PRC)',
  'zh-sg': 'Chinese (Singapore)',
  'zh-tw': 'Chinese (Taiwan)',
  cv: 'Chuvash',
  co: 'Corsican',
  cr: 'Cree',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  nl: 'Dutch (Standard)',
  'nl-be': 'Dutch (Belgian)',
  en: 'English',
  'en-au': 'English (Australia)',
  'en-bz': 'English (Belize)',
  'en-ca': 'English (Canada)',
  'en-ie': 'English (Ireland)',
  'en-jm': 'English (Jamaica)',
  'en-nz': 'English (New Zealand)',
  'en-ph': 'English (Philippines)',
  'en-za': 'English (South Africa)',
  'en-tt': 'English (Trinidad & Tobago)',
  'en-gb': 'English (United Kingdom)',
  'en-us': 'English (United States)',
  'en-zw': 'English (Zimbabwe)',
  eo: 'Esperanto',
  et: 'Estonian',
  fo: 'Faeroese',
  fj: 'Fijian',
  fi: 'Finnish',
  fr: 'French (Standard)',
  'fr-be': 'French (Belgium)',
  'fr-ca': 'French (Canada)',
  'fr-fr': 'French (France)',
  'fr-lu': 'French (Luxembourg)',
  'fr-mc': 'French (Monaco)',
  'fr-ch': 'French (Switzerland)',
  fy: 'Frisian',
  fur: 'Friulian',
  'gd-ie': 'Gaelic (Irish)',
  gl: 'Galacian',
  ka: 'Georgian',
  de: 'German (Standard)',
  'de-at': 'German (Austria)',
  'de-de': 'German (Germany)',
  'de-li': 'German (Liechtenstein)',
  'de-lu': 'German (Luxembourg)',
  'de-ch': 'German (Switzerland)',
  el: 'Greek',
  gu: 'Gujurati',
  ht: 'Haitian',
  he: 'Hebrew',
  hi: 'Hindi',
  hu: 'Hungarian',
  is: 'Icelandic',
  id: 'Indonesian',
  iu: 'Inuktitut',
  ga: 'Irish',
  it: 'Italian (Standard)',
  'it-ch': 'Italian (Switzerland)',
  ja: 'Japanese',
  kn: 'Kannada',
  ks: 'Kashmiri',
  kk: 'Kazakh',
  km: 'Khmer',
  ky: 'Kirghiz',
  tlh: 'Klingon',
  ko: 'Korean',
  'ko-kp': 'Korean (North Korea)',
  'ko-kr': 'Korean (South Korea)',
  la: 'Latin',
  lv: 'Latvian',
  lt: 'Lithuanian',
  lb: 'Luxembourgish',
  mk: 'FYRO Macedonian',
  ms: 'Malay',
  ml: 'Malayalam',
  mt: 'Maltese',
  mi: 'Maori',
  mr: 'Marathi',
  mo: 'Moldavian',
  nv: 'Navajo',
  ng: 'Ndonga',
  ne: 'Nepali',
  no: 'Norwegian',
  nb: 'Norwegian (Bokmal)',
  nn: 'Norwegian (Nynorsk)',
  oc: 'Occitan',
  or: 'Oriya',
  om: 'Oromo',
  fa: 'Persian/Farsi',
  'fa-ir': 'Persian/Iran',
  pl: 'Polish',
  pt: 'Portuguese',
  'pt-br': 'Portuguese (Brazil)',
  pa: 'Punjabi',
  'pa-in': 'Punjabi (India)',
  'pa-pk': 'Punjabi (Pakistan)',
  qu: 'Quechua',
  rm: 'Rhaeto-Romanic',
  ro: 'Romanian',
  'ro-mo': 'Romanian (Moldavia)',
  ru: 'Russian',
  'ru-mo': 'Russian (Moldavia)',
  sz: 'Sami (Lappish)',
  sg: 'Sango',
  sa: 'Sanskrit',
  sc: 'Sardinian',
  gd: 'Scots Gaelic',
  sd: 'Sindhi',
  si: 'Singhalese',
  sr: 'Serbian',
  sk: 'Slovak',
  sl: 'Slovenian',
  so: 'Somani',
  sb: 'Sorbian',
  es: 'Spanish',
  'es-ar': 'Spanish (Argentina)',
  'es-bo': 'Spanish (Bolivia)',
  'es-cl': 'Spanish (Chile)',
  'es-co': 'Spanish (Colombia)',
  'es-cr': 'Spanish (Costa Rica)',
  'es-do': 'Spanish (Dominican Republic)',
  'es-ec': 'Spanish (Ecuador)',
  'es-sv': 'Spanish (El Salvador)',
  'es-gt': 'Spanish (Guatemala)',
  'es-hn': 'Spanish (Honduras)',
  'es-mx': 'Spanish (Mexico)',
  'es-ni': 'Spanish (Nicaragua)',
  'es-pa': 'Spanish (Panama)',
  'es-py': 'Spanish (Paraguay)',
  'es-pe': 'Spanish (Peru)',
  'es-pr': 'Spanish (Puerto Rico)',
  'es-es': 'Spanish (Spain)',
  'es-uy': 'Spanish (Uruguay)',
  'es-ve': 'Spanish (Venezuela)',
  sx: 'Sutu',
  sw: 'Swahili',
  sv: 'Swedish',
  'sv-fi': 'Swedish (Finland)',
  'sv-sv': 'Swedish (Sweden)',
  ta: 'Tamil',
  tt: 'Tatar',
  te: 'Teluga',
  th: 'Thai',
  tig: 'Tigre',
  ts: 'Tsonga',
  tn: 'Tswana',
  tr: 'Turkish',
  tk: 'Turkmen',
  uk: 'Ukrainian',
  hsb: 'Upper Sorbian',
  ur: 'Urdu',
  ve: 'Venda',
  vi: 'Vietnamese',
  vo: 'Volapuk',
  wa: 'Walloon',
  cy: 'Welsh',
  xh: 'Xhosa',
  ji: 'Yiddish',
  zu: 'Zulu'
};
