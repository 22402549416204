import React from 'react';
import styles from './Input.module.css';
import VectorError from '../../../assets/images/Vector.png';

interface InputProps {
  label: string;
  id: string;
  name: string;
  placeholder?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler;
  errorType?: string;
  touched?: boolean;
  type: 'text' | 'date';
  className?: string;
  inputClassName?: string;
  inputVectorImgClassName?: string;
}

const Input = (props: InputProps) => {
  const errorTextHandler = (errorText: string) => {
    if (errorText === 'pinIsInvalid') {
      return 'PIN code incorrect';
    } else if (errorText === 'emptyField') {
      return 'Please enter a valid PIN';
    } else if (errorText === 'emailIsEmpty') {
      return 'Please enter booker email';
    } else {
      return 'Invalid value';
    }
  };
  return (
    <div className={`${styles.inputContainer} ${props.className}`} data-testid={'inputDivTestId'}>
      <label htmlFor={props.id}>{props.label}</label>
      {props.errorType && props.touched && (
        <img
          className={`${styles.vectorImg} ${props.inputVectorImgClassName}`}
          src={VectorError}
          alt={'errorInput'}
        />
      )}
      <input
        className={`${props.inputClassName}`}
        data-testid="inputELTestId"
        style={props.errorType && props.touched ? { border: '1px solid #CC0000' } : {}}
        id={props.id}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
      />
      {props.errorType && props.touched && (
        <div className={styles.invalidInput} data-testid="errorMessage" role={'alert'}>
          {errorTextHandler(props.errorType)}
        </div>
      )}
    </div>
  );
};

export default Input;
