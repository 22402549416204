import { serviceConstants, reservationStatus, NO_INFO_AVAILABLE, langCodes } from './contants';
const getNumberOfMilliseconds = 8.64e7; // 8.64e7 is the number of milliseconds in a day.
export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const monthFullNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const getAccessTokenHandler = () => {
  const token = JSON.parse(
    localStorage.getItem(serviceConstants.OKTA_TOKEN_KEY) as string
  )?.accessToken;
  if (token) {
    return token;
  } else {
    return ' ';
  }
};
export const getMonth = (epochDate: string) => {
  const dateFormat = new Date(+epochDate * getNumberOfMilliseconds);
  const month = monthNames[dateFormat.getMonth()];
  return `${month}`;
};

export const getFullMonth = (epochDate: string) => {
  const dateFormat = new Date(+epochDate * getNumberOfMilliseconds);
  const month = monthFullNames[dateFormat.getMonth()];
  return `${month}`;
};

export const getYear = (epochDate: string) => {
  const dateFormat = new Date(+epochDate * getNumberOfMilliseconds);
  const fullYear = dateFormat.getFullYear();
  return `${fullYear}`;
};

export const getDay = (epochDate: string) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dateFormat = new Date(+epochDate * getNumberOfMilliseconds);
  const day = days[dateFormat.getDay()];
  return `${day}`;
};

export const getDate = (epochDate: string) => {
  const dateFormat = new Date(+epochDate * getNumberOfMilliseconds);
  const date = dateFormat.getDate();
  return `${date}`;
};

export const getDateFormat = (epochDate: string) => {
  return `${getDate(epochDate)} ${getMonth(epochDate)} ${getYear(epochDate)}`;
};

export const getDateFormatFromTimeStamp = (timestamp: string) => {
  const dateFormat = new Date(+timestamp * 1000);
  return {
    bookedDate: `${dateFormat.getDate()} ${
      monthNames[dateFormat.getMonth()]
    } ${dateFormat.getFullYear()}`,
    bookedTime: `${dateFormat.getHours()}:${dateFormat.getMinutes()}`
  };
};
export const getEpochFromDate = (bookedDate: Date) => {
  const getBookedDateTime = bookedDate.getTime() + Math.abs(bookedDate.getTimezoneOffset() * 60000);
  return Math.floor(getBookedDateTime / getNumberOfMilliseconds);
};

export const reformatReservationId = (id: string) => {
  const idArray = Array.from(id);
  return `${idArray.slice(0, 4).join('')}.${idArray.slice(4, 7).join('')}.${idArray
    .slice(7, 10)
    .join('')}`;
};

export const getReservationStatus = (status: number) => {
  return (Object as any)
    .values(reservationStatus)
    .find((item: { key: number; status: string }) => item.key === status).status;
};

export const reformatName = (firstName: string, lastName: string) => {
  if (firstName === NO_INFO_AVAILABLE) {
    return NO_INFO_AVAILABLE;
  } else {
    return `${firstName} ${lastName}`;
  }
};

export const sortFieldFormat = (order: boolean) => {
  if (order) {
    return 'ASCENDING';
  } else {
    return 'DESCENDING';
  }
};

export const tableHeaderFormat = (key: string) => {
  //hotelReservationId,bookerName,checkin,hotelName,checkout,created,price
  switch (key) {
    case 'Confirmation number':
      return 'hotelReservationId';
    case 'Name':
      return 'bookerName';
    case 'Check-in date':
      return 'checkin';
    case 'Check-out date':
      return 'checkout';
    case 'Property':
      return 'hotelName';
    case 'Booked on':
      return 'created';
    case 'Price':
      return 'price';
    default:
      return 'hotelReservationId';
  }
};

const arrayOfMultiples = (limit: number, length: number) => {
  const arr = [];
  let x = 1;
  for (let i = 1; i <= length; i++) {
    arr.push(limit * x);
    x++;
  }
  return arr;
};
export const pageSizeArray = (pageLimit: number, totalRecords: number) => {
  const length = Math.ceil(totalRecords / pageLimit);
  return arrayOfMultiples(pageLimit, length);
};

export const reformatUsernameField = (firstName: string, lastName: string) => {
  if (firstName === NO_INFO_AVAILABLE && lastName === NO_INFO_AVAILABLE) {
    return NO_INFO_AVAILABLE;
  } else if (firstName === NO_INFO_AVAILABLE) {
    return lastName;
  } else if (lastName === NO_INFO_AVAILABLE) {
    return firstName;
  } else {
    return `${firstName} ${lastName}`;
  }
};

export const reformatTimestamp = (timestamp: string) => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'CET',
    timeZoneName: 'short',
    hour12: false,
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };

  const formatter = new Intl.DateTimeFormat('en-UK', options);
  const formattedDate = formatter.format(date);

  const offsetHours = 1; // Offset for CET (GMT+1)
  const offsetAbbr = offsetHours >= 0 ? `GMT+${offsetHours}` : `GMT${offsetHours}`;

  const finalFormattedDate = `${('0' + date.getHours()).slice(-2)}:${(
    '0' + date.getMinutes()
  ).slice(-2)} CET (${offsetAbbr}) on ${formattedDate.split(',')[0]}
                              ${date.getDate()}${
    date.getDate() === 1 ? 'st' : date.getDate() === 2 ? 'nd' : date.getDate() === 3 ? 'rd' : 'th'
  }
                              ${formattedDate.split(' ')[2]}
                              ${date.getFullYear()}`;
  return finalFormattedDate;
};

export const languageMapping = (code: string) => {
  if (code === NO_INFO_AVAILABLE) {
    return NO_INFO_AVAILABLE;
  } else {
    return langCodes[code as keyof typeof langCodes];
  }
};
