import React from 'react';
import styles from './Card.module.css';

interface ICard {
  children?: React.ReactNode;
  className?: string;
}
const Card = ({ children, className }: ICard) => {
  return (
    <div data-testid={'cardTestId'} className={`${styles.cardContainer} ${className}`}>
      {children}
    </div>
  );
};

export default Card;
