import React from 'react';

import styles from './FormSelectInput.module.css';
import VectorError from '../../assets/images/Vector.png';
import { ErrorMessage, Field } from 'formik';

interface ISelectProps {
  label: string;
  id: string;
  name: string;
  placeholder: string;
  value: string | undefined;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  handleChange: React.ChangeEventHandler<HTMLSelectElement>;
  errorType: string | undefined;
  touched: boolean | undefined;
  values: any[];
}

const FormSelectInput = (props: ISelectProps) => {
  return (
    <div className={styles.formInput} data-testid="formInputTestId">
      <label htmlFor={props.id} data-testid="inputLabel">
        {props.name}
      </label>
      <Field
        as="select"
        id={props.id}
        className={styles.selectControl}
        style={props.errorType ? { border: '1px solid red' } : {}}
        value={props.value}
        data-testid={`${props.id}_options_testId`}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          props.onChange(e);
          props.handleChange(e);
        }}>
        <option value="" className={styles.defaultOption}>
          {props.placeholder}
        </option>
        {props.values.map((val, index) => (
          <option key={index} value={val}>
            {val}
          </option>
        ))}
      </Field>
      <ErrorMessage name={props.id} component="div" className={styles.invalidInput} />
      {props.errorType && props.touched && (
        <img className={styles.vectorImg} src={VectorError} alt={'errorInput'} />
      )}
    </div>
  );
};

export default FormSelectInput;
