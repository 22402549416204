import React, { useState, useContext, useEffect } from 'react';
import Card from '../UI/card/Card';
import styles from './ReservationTable.module.css';
import SortTable from '../UI/sortTable/SortTable';
import Pagination from '../UI/pagination/Pagination';
import BasicSelect from '../UI/basicSelect/BasicSelect';
import { IApiParametersValues, IreservationDetails } from '../../interfaces';
import ReservationContext from '../../context/ReservationContext';
import { paginationConstants } from '../../utils/contants';
interface IReservationTable {
  reservationDetails: {
    totalNumberOfResults: number;
    response: Array<IreservationDetails>;
  };
  getReservationTableDetailsHandler: (values: IApiParametersValues) => void;
}

const ReservationTable = ({
  reservationDetails,
  getReservationTableDetailsHandler
}: IReservationTable) => {
  const reservationCtx = useContext(ReservationContext);
  const [currentPage, setCurrentPage] = useState(+paginationConstants.pageNumber);
  const [pageSize, setPageSize] = useState(+paginationConstants.pageLimit);

  useEffect(() => {
    setCurrentPage(+reservationCtx.pageNumber);
    setPageSize(+reservationCtx.pageLimit);
  }, [reservationDetails]);

  const sortReservationTableHandler = (key: string, order: string) => {
    reservationCtx.pageNumber = currentPage.toString();
    reservationCtx.sortField = key;
    reservationCtx.sortOrder = order;
    reservationCtx.pageLimit = pageSize.toString();
    getReservationTableDetailsHandler(reservationCtx);
  };
  const changePageSizeHandler = (size: number) => {
    setPageSize(size);
    reservationCtx.pageLimit = size.toString();
    reservationCtx.pageNumber = paginationConstants.pageNumber;
    setCurrentPage(+paginationConstants.pageNumber);
    getReservationTableDetailsHandler(reservationCtx);
  };
  const getPageStart = (pageLimit: number, pageNr: number) => {
    return pageLimit * pageNr;
  };
  const getPageLabel = (total: number, pageLimit: number, pageNr: number) => {
    const start = Math.max(getPageStart(pageLimit, pageNr), 0);
    const end = Math.min(getPageStart(pageLimit, pageNr + 1), total);

    return `${start + 1} - ${end}`;
  };
  const itemsToShow = Array.from(
    { length: reservationDetails.totalNumberOfResults },
    (_, i) => `Item ${i + 1}`
  );
  const intervals = Array.from({ length: Math.ceil(itemsToShow.length / pageSize) }, (_, i) =>
    getPageLabel(itemsToShow.length, pageSize, i)
  );
  return (
    <Card className={styles.reservationTableContainer}>
      <h3>{`Found ${reservationDetails.totalNumberOfResults} matching reservations`}</h3>
      <p>Click on a column heading to change the sort order.</p>
      <SortTable
        reservationDetails={reservationDetails}
        sortReservationTableHandler={sortReservationTableHandler}
      />
      {reservationDetails.totalNumberOfResults > 9 && (
        <div className={styles.paginationContainer}>
          <Pagination
            className={styles.paginationBarContainer}
            currentPage={currentPage}
            totalCount={reservationDetails.totalNumberOfResults}
            pageSize={pageSize}
            onPageChange={(page) => {
              setCurrentPage(Number(page));
              reservationCtx.pageNumber = page as string;
              getReservationTableDetailsHandler(reservationCtx);
            }}
          />
          <div className={styles.totalNumberResultsContainer}>
            <p>
              {intervals[currentPage - 1]} of {reservationDetails.totalNumberOfResults} results
            </p>
          </div>
          <div className={styles.totalNumberResultsContainer}>
            <BasicSelect pageSize={pageSize} changePageSizeHandler={changePageSizeHandler} />
          </div>
        </div>
      )}
    </Card>
  );
};
export default ReservationTable;
