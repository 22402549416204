import React from 'react';
import styles from './AppInfo.module.css';
import infoSign from '../../assets/images/Info-sign.png';

const AppInfo = () => {
  return (
    <div className={styles.appInfoContainer} data-testid={'appInfoDivTestId'}>
      <img className={styles.infoSign} src={infoSign} alt={'Info sign'} />
      <div>
        <span>
          <strong>
            Booking.com is currently experiencing technical issues affecting many platforms.
          </strong>
        </span>
        <p className={styles.alertInfo}>
          We are working hard to solve the problem and hope to be back online again as soon as
          possible. Please refer to the procedures relating to crisis continuity during this time.
        </p>
      </div>
    </div>
  );
};

export default AppInfo;
