import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styles from './BasicSelect.module.css';

interface IBasicSelect {
  pageSize: number;
  changePageSizeHandler: (size: number) => void;
}

export default function BasicSelect({ pageSize, changePageSizeHandler }: IBasicSelect) {
  const handleChange = (event: SelectChangeEvent) => {
    changePageSizeHandler(parseInt(event.target.value, 10));
  };
  const values = ['10', '25', '50', '100'];
  return (
    <Box sx={{ minWidth: 60 }} data-testid="basicSelectTestId">
      <span className={styles.pageSizeValue}>{pageSize} results</span>
      <FormControl>
        <Select
          sx={{
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 0
            }
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          data-testid="demoSimpleSelectTestId"
          value={''}
          onChange={handleChange}>
          {values.map((x) => (
            <MenuItem key={x} value={x}>
              {x}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
